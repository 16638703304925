import React from 'react';
import Image from 'next/image';

interface IPostTagline {
    label: string;
    primary: boolean;
    showLogo?: boolean;
}

const PostTag: React.FC<IPostTagline> = (props) => {
    const { showLogo, label, primary } = props;

    return (
        <>
            <div
                className={`relative inline-flex uppercase font-serif items-center justify-center px-2 md:px-2 mb-2 py-0.5 rounded-full text-xs md:text-sm font-medium md:mt-2 lg:mt-0 mx-1 tracking-wide whitespace-nowrap ${
                    primary ? 'bg-secondary text-white' : 'bg-gray-200 text-gray-500'
                } `}
            >
                {showLogo && (
                    <>
                        <div className="relative flex h-5/6 mr-1 ">
                            <Image
                                src="/images/favicon.svg"
                                alt="teaserimage"
                                objectFit="contain"
                                width="24"
                                height="24"
                            />
                        </div>
                    </>
                )}

                {label}
            </div>
        </>
    );
};

PostTag.defaultProps = {
    showLogo: false,
};

export default PostTag;
