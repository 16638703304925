export interface IPost {
    slug: string;
    tags: string[];
    title: string;
    date: string;
    isSticky?: boolean;
    excerpt: string;
    imageType: 'png' | 'jpeg' | 'jpg';
    imageDescription: string;
    readingTime: number;
    mediumHref?: string;
    linkedInHref?: string;
}

export const postList: IPost[] = [
    {
        slug: 'game-changing-safari-15-4-update-for-web-apps',
        title: 'Game-Changing Safari 15.4 update for Web-Apps',
        tags: ['apple', 'filesystem', 'safari', 'ios', 'pwa'],
        date: '2022-04-26T00:00:00.000Z',
        imageType: 'png',
        imageDescription: 'Standard Licence Adobe Stock 486224239',
        readingTime: 4,
        linkedInHref: 'https://www.linkedin.com/feed/update/urn:li:activity:6927261754435514368',
        mediumHref:
            'https://adrianstanek.medium.com/game-changer-for-web-apps-safaris-15-2-update-including-file-system-access-api-33f9219deb9a',
        excerpt:
            'Web development has been essential in my life for many years now. However, while it is so fascinating to me, it got its downsides which often lead to frustration. One of the worst things that annoyed me was that there were no fundamental standards, and many good features of browser A were not available on browser',
    },
    {
        slug: 'persisting-data-in-modern-web-apps',
        title: 'Persisting data in modern Web-Apps',
        tags: ['filesystem', 'localstorage', 'pwa'],
        date: '2022-04-21T00:00:00.000Z',
        imageType: 'png',
        readingTime: 9,
        linkedInHref: 'https://www.linkedin.com/feed/update/urn:li:activity:6922838192568078336',
        mediumHref:
            'https://adrianstanek.medium.com/persisting-data-in-modern-web-apps-33a77234ced3',
        imageDescription: '',
        excerpt:
            "It wasn't long ago when cookies were the only viable option to store data persistently in the browser. Speaking of cookies, we talk about a limited string of characters representing the storage capabilities for quite a long time.\n" +
            "But technology evolved, and so the browsers have done too. As a result, today's options to temporarily store data or persist in the browser are far better than some years ago and eventually become useful when it comes to application development.",
    },
    {
        slug: 'continuous-modernization-in-web-development',
        title: 'Continuous Modernization in web development',
        tags: ['architecture', 'MACH', 'modernization'],
        date: '2022-02-03T00:00:00.000Z',
        imageType: 'jpg',
        readingTime: 5,
        imageDescription: '',
        linkedInHref:
            'https://www.linkedin.com/pulse/continuous-modernization-web-development-adrian-stanek/',
        excerpt:
            "-Today's development becomes yesterday's legacy- is somewhat exaggerated, but the essence is, unfortunately, a fact.\n" +
            '  I want to describe how Continuous Modernization has become a part of daily life in our development environment (DevOps) at bitsinmotion. A little more than three years ago, we were forced to adapt our development strategies due to the many legacy applications we were maintaining at that time.\n' +
            '  I will describe why modernization should be a part of the daily development process and which architectures we use for it in the following article.\n',
    },
    {
        slug: 'evolve-in-baby-steps-a-way-of-modernizing-legacy-apps',
        title: 'Evolve in Baby Steps – A way of modernizing legacy apps',
        tags: ['architecture', 'MACH'],
        date: '2022-01-31T00:00:00.000Z',
        imageType: 'png',
        readingTime: 6,
        imageDescription: '',
        linkedInHref:
            'https://www.linkedin.com/pulse/evolve-baby-steps-way-modernizing-legacy-apps-adrian-stanek/',
        excerpt:
            "A company is facing the constantly ongoing evolution of technology. Therefore, small companies cannot stand still for too long and try to sit out update after update. There's a time coming to act and step up in the game. But what happens when the gap between the companies state and desired one becomes too wide?",
    },
    {
        slug: 'developing-the-first-app-as-a-start-up-dont-waste-your-money',
        title: "Developing the first App as a Start-Up - Don't waste your money",
        tags: ['apps', 'pwa', 'startups', 'mvp'],
        date: '2022-01-15T00:00:00.000Z',
        imageType: 'jpg',
        readingTime: 5,
        imageDescription: '',
        linkedInHref:
            'https://www.linkedin.com/pulse/developing-first-app-start-up-dont-waste-your-money-adrian-stanek/',
        excerpt:
            'Start-ups and small companies usually face the same challenges; Creating a digital solution to a problem in the market. When accomplishing the business plan, the entrepreneurs face the operative challenge of developing a digital product. But how does one sensibly begin planning for such a product? Buzzwords like Cloud-Native, Serverless, App Stores, Microservices, and DevOps seem to overwhelm smaller companies real quick; how to put those Buzzwords into context, and what do I as an SME need to develop and ship an app?',
    },
    {
        slug: 'partnership-based-software-development-should-replace-project-orientation',
        title: 'Partnership-based software development should replace project orientation',
        tags: ['agile', 'devops', 'partnership'],
        date: '2022-01-15T00:00:00.000Z',
        imageType: 'jpg',
        readingTime: 5,
        imageDescription: '',
        excerpt:
            'For many years now, I have often noticed the same thing: No matter with which customers or in which constellations software solutions are projected, the result does not meet the requirement. This does not mean that it went wrong, the idea has simply changed during the project and so has the result. This is reality and not a phenomenon.',
    },
    {
        slug: 'microservices-or-nanoservices-it-all-depends-on-the-right-scope',
        title: 'Microservices or Nanoservices – It all depends on the right scope',
        tags: ['microservices', 'nanoservices', 'cloud-functions'],
        date: '2022-01-05T00:00:00.000Z',
        imageType: 'jpeg',
        readingTime: 4,
        imageDescription: '',
        excerpt:
            'Microservices define themselves by the term "micro" and thus try to be as small as possible. But how small is the right "micro"? How do you define the scope of functions and the size of a microservice without getting lost in the smallness?',
    },
    {
        slug: 'microservices-vs-monoliths-how-to-migrate-to-a-modern-architecture',
        title: 'Microservices vs. Monoliths – In the scope of small teams',
        tags: ['microservices', 'nanoservices', 'MVP', 'start-up'],
        date: '2021-12-24T00:00:00.000Z',
        imageType: 'jpg',
        readingTime: 5,
        imageDescription: '',
        excerpt:
            'I regularly read and talked about microservices versus monoliths in software landscape solutions in the past weeks. Statements like "Microservices cannot be recommended for teams below 50 developers" or "There is too much overhead and too little benefit to migrating to microservices." Often heard about a widespread assumption that a single microservice must be maintained by a designated team for its own.',
    },
    {
        slug: 'progressive-web-apps-key-b2b-technology-in-digital-transformation-for-small-businesses',
        title: 'Progressive Web Apps – Key Technology in Digital Transformation for B2B Businesses',
        tags: ['pwa', 'apps', 'SME', 'frontend'],
        date: '2021-12-09T00:00:00.000Z',
        imageType: 'jpg',
        readingTime: 5,
        imageDescription: 'Adobe Stock 254301905 (Standard Licence)',
        mediumHref:
            'https://adrianstanek.medium.com/progressive-web-apps-key-technology-in-digital-transformation-for-b2b-b92302d0cfd9',
        excerpt:
            "Wrote an article about a topic which can be interesting for small to medium-sized companies. It's the first one of a series about modern web apps. From my personal experience with our customers and partners, Digital Transformation is becoming more critical every day. The large tech-driven companies are doing well, but what is with the small B2B?\n" +
            '\n' +
            'How can startups or small teams follow their visions and make them a reality? \n' +
            'How can traditional small companies keep up with the pace of the modern internet world?\n' +
            '\n' +
            'This post will cover what I experience in my daily business with a real-world example to find ways to accomplish those visions and goals. ',
    },
    {
        slug: 'keeping-pace-in-modern-web-development',
        title: 'Keeping Pace in Modern Web Development',
        tags: ['learning', 'development'],
        date: '2022-06-30T00:00:00.000Z',
        imageType: 'jpg',
        readingTime: 7,
        imageDescription: 'AdobeStock_88140472',
        linkedInHref: 'https://www.linkedin.com/feed/update/urn:li:activity:6947957088052502528',
        mediumHref:
            'https://adrianstanek.medium.com/keeping-pace-in-modern-web-development-69e4bd887787',
        excerpt:
            'Small Companies and Start-Ups are facing new challenges in web development these days. I will share some of my experiences in this article.',
    },
    {
        slug: 'apps-web-apps-mobile-apps-natives-so-many-apps',
        title: 'Apps, Web Apps, Mobile Apps, Native Apps, so many Apps!',
        tags: ['apps', 'pwa', 'mobile'],
        date: '2022-06-29T00:00:00.000Z',
        imageType: 'jpg',
        readingTime: 7,
        imageDescription: 'AdobeStock_96138441.jpeg',
        linkedInHref: 'https://www.linkedin.com/feed/update/urn:li:activity:6948298039253987328/',
        mediumHref:
            'https://adrianstanek.medium.com/apps-web-apps-mobile-apps-natives-so-many-apps-e95ef2f0d7a7',
        excerpt:
            'How does a small company or Start-Up decide on what to put the limited budget? In this article, I share my opinion on why web apps have become a suitable solution.',
    },
    {
        slug: 'I-don-t-need-to-go-Cloud-Native-do-I',
        title: 'I do not need to go Cloud-Native, do I? The fears of change',
        tags: ['cloud-native', 'CTO'],
        date: '2022-07-22T00:00:00.000Z',
        imageType: 'jpg',
        readingTime: 6,
        mediumHref: 'https://medium.com/p/79cf26eed9cf',
        linkedInHref: 'https://www.linkedin.com/feed/update/urn:li:activity:6957297697254957056/',
        imageDescription: 'AdobeStock_310014204',
        excerpt:
            'How vital is a Cloud-Native transition for traditional web companies? Do you want to move your applications into the cloud but hesitate because of insecurity?',
    },
    {
        slug: 'stay-up-to-date-with-skills-mindset-and-team-culture',
        title: 'Stay up to date with skills, mindset, and team culture.',
        tags: ['Quality', 'culture', 'learning', 'mindset'],
        date: '2022-08-01T11:00:00.000Z',
        imageType: 'jpg',
        readingTime: 4,
        imageDescription: 'AdobeStock_503543452 + custom text',
        mediumHref:
            'https://adrianstanek.medium.com/stay-up-to-date-with-skills-mindset-and-team-culture-61ad487c237',
        linkedInHref:
            'https://www.linkedin.com/posts/adrianstanek_skillset-mindset-continuouslearning-activity-6959815320530214913-A5rm?utm_source=linkedin_share&utm_medium=member_desktop_web',
        excerpt:
            'I’ve recorded a podcast with a colleague about the requirements to migrate into the cloud. A significant portion of the talk was about the **level of maturity** of the team and a single developer. That made me think even more about the problems our team and I had many years ago and how we had overcome these problems step by step. Why is that important for every one of us?',
    },
    {
        slug: 'you-need-to-fix-your-problems-fast-and-adequate',
        title: 'You need to fix your problems fast and adequate - the  Broken Window Theory ',
        tags: ['Quality', 'culture', 'CTO', 'mindset'],
        date: '2022-08-02T14:00:00.000Z',
        imageType: 'jpg',
        readingTime: 3,
        imageDescription: 'AdobeStock_292207665 + custom text',
        linkedInHref: 'https://www.linkedin.com/feed/update/urn:li:activity:6960205104440344576/',
        mediumHref:
            'https://adrianstanek.medium.com/you-need-to-fix-your-problems-fast-and-adequately-the-broken-window-theory-9dfa7ecf3f2c',
        excerpt:
            "As developers, we know how often we feel resistance to doing things the right way. Especially when we are under stress or have deadlines, don't mistake shortcutting things when a good solution should be implemented.",
    },
    {
        slug: 'learn-and-let-learn',
        title: 'Learn and let learn',
        tags: ['learning', 'enhancement', 'mindset', 'team culture'],
        date: '2022-08-06T11:00:00.000Z',
        imageType: 'jpg',
        readingTime: 4,
        imageDescription: 'AdobeStock_308986758',
        mediumHref:
            'https://adrianstanek.medium.com/today-i-have-a-short-but-essential-message-for-you-2b7e990c80c2',
        excerpt:
            "Today I have a short but essential message for you. It's about the value of experience, the sum of your learnings. Of course, since my newsletter is mainly directed to IT, I refer to developer teams. But the following message is transferable to every type of team in every profession.",
    },
    {
        slug: 'legacy-migration-maintain-short-update-cycles',
        title: 'Legacy Migration: Maintain Short Update Cycles — Deliver value fast and often',
        tags: ['migration', 'legacy', 'small batch sizes'],
        date: '2022-08-05T11:00:00.000Z',
        imageType: 'jpg',
        readingTime: 5,
        imageDescription: 'AdobeStock_500413062',
        mediumHref:
            'https://adrianstanek.medium.com/legacy-migration-maintain-short-update-cycles-deliver-value-fast-and-often-d40aa02b8397',
        excerpt:
            'This article is about solving migration problems with modern approaches of short iteration cycles.',
    },
    {
        slug: 'make-or-buy-an-essential-decision-with-a-high-impact',
        title: 'Make or Buy - An essential decision with a high impact.',
        tags: ['SaaS', 'makeOrBuy'],
        date: '2022-08-08T16:00:00.000Z',
        imageType: 'jpg',
        readingTime: 3,
        imageDescription: 'AdobeStock_184087577',
        linkedInHref: 'https://www.linkedin.com/feed/update/urn:li:activity:6962763101427396609/',
        excerpt:
            'In the past decades, creating custom features in custom applications was very common. However, in the last years, the SaaS market exploded with solutions for every problem.The modern approach to creating software on the web is the composable one.',
    },
    {
        slug: 'requirements-aim-to-hit-the-expected-quality-not-higher-or-lower',
        title: 'Requirements: Aim to hit the expected Quality, not higher or lower',
        tags: ['Quality', 'QA', 'CTO', 'Planning'],
        date: '2023-02-21T16:00:00.000Z',
        imageType: 'jpg',
        readingTime: 3,
        imageDescription: 'AdobeStock_497462673',
        excerpt:
            'Requirements and Quality are critical to avoid problems later. Make sure you define them properly.',
    },
];
