import dayjs from 'dayjs';
import { useCallback, useMemo } from 'react';

import { IPostData } from '../Components/molecules/ListPost/ListPost';
import { IPost, postList } from '../content/postList';

const usePostGet = () => {
    const list = useMemo((): IPostData[] => {
        const newList: IPostData[] = [];

        postList.forEach((post) => {
            newList.push({
                author: {
                    name: 'Adrian Stanek',
                    href: '',
                    imageUrl: '/images/adrianstanek.jpg',
                    description: 'CTO',
                },
                imageUrl: `/posts/${post.slug}.${post.imageType}`,
                imageDescription: post.imageDescription ?? '',
                href: `/post/${post.slug}`,
                category: {
                    name: '',
                    href: '',
                },
                date: post.date,
                description: post.excerpt,
                isSticky: post.isSticky,
                tags: post.tags,
                title: post.title,
                readingTime: post.readingTime,
                excerpt: post.excerpt,
                linkedInHref: post.linkedInHref ?? '',
                mediumHref: post.mediumHref ?? '',
            });
        });

        newList.sort((a, b) => dayjs(b.date).unix() - dayjs(a.date).unix());

        return [...newList];
    }, []);

    const getPostBySlug = useCallback((slug: string): IPostData | null => {
        let found: IPost | null;

        postList.forEach((post) => {
            if (post.slug === slug) {
                found = post;
            }
        });

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        if (found) {
            return {
                author: {
                    name: 'Adrian Stanek',
                    href: '',
                    imageUrl: '/images/adrianstanek.jpg',
                    description: 'CTO',
                },
                imageUrl: `/posts/${found.slug}.${found.imageType}`,
                imageDescription: found.imageDescription ?? '',
                href: `/post/${found.slug}`,
                category: {
                    name: '',
                    href: '',
                },
                date: found.date,
                description: found.excerpt,
                isSticky: found.isSticky,
                tags: found.tags,
                title: found.title,
                readingTime: found.readingTime,
                excerpt: found.excerpt,
                linkedInHref: found.linkedInHref ?? '',
                mediumHref: found.mediumHref ?? '',
            } as IPostData;
        }

        return null;
    }, []);

    return { list, getPostBySlug };
};

export default usePostGet;
